/*
Common
*/
html, body {
 /*  height: 100% !important; */
  background-color: #F8F8F8 !important;
  margin: 0;
  padding: 0;
}
#root {
  height: 100% !important;
  background-color: #F8F8F8 !important;
  
}
.common-spinner {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  min-width: 186px !important;
  height: 100% !important;
  justify-content: center !important;
  position: fixed !important;
  left: 0px !important;
  top: 0px !important;
  z-index: 9999 !important;
  background-color: rgba(255,255,255,0.5) !important;
}
.loader-circualar-spinner {
  width: 20px !important;
  height: 20px !important;
}
.common-mandatory-red {
  border: none
}
.common-mandatory-red > div {
  border: 1px solid red !important;
}
.common-mandatory-none {
  border-color: none;
}
.common-background {
  background-color: #F8F8F8 !important;
}
.csv-export-spinner {
  color: #909199 !important;
  margin-right: 8px !important;
}

.csv-export-text-disabled {
  color: #909199 !important;
}

.csv-export-text-enabled {
  color: #000000 !important;
}

.loader-circualar {
  width: 20px !important;
  height: 20px !important;
  margin-left: 0 !important;
  margin-right: 16px !important;
  color: #3f51b5 !important;
}

.menuappbar-root {
  flex-grow: 1 !important;
  background-color: #cccccc !important;
}
.menuappbar-toggle-bt {
  margin-right: 16px !important;
}
.menuappbar-title {
  /* flex-grow: 1 !important; */
  font-size: 1rem !important;
  font-weight: 400 i !important;
  min-width: 9% !important;
  margin-right: 15px !important;
}
.menuappbar-menuButton {
  margin-right: 16px !important;
}
.menuappbar-moreVert {
  color: #fff !important;
}
.homeBox-alone{
  padding: 6px 20px !important;
}

.homeBox{
  color: rgba(255,255,255,1) !important;
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
}

.homeIcon {
  margin-right: 12px !important;
  width: 24px !important;
  height: 24px !important;
}

.addLease-mandatory-tenant {
  border: 1px solid red !important;
}
/*
SWIPABLE DRAWER
*/

.Swipe-bg {
    background-color: black;
  }
.Swipe-Parent .MuiPaper-root{
  background-color: black;
  width: 18.5%;
  min-width: 300px
}
.swipe-menu-divider{
  background-color: rgb(240, 236, 236) !important;
  opacity: 12% !important;
  height: 2px !important;
}
.swipe-menu-icon{
  color:white;
}
.swipe-menu-listtext{
  color:white;
}
.swipe-menu-sub-listtext{
  color:white;
}
.swipe-menu-listtext span {
  font-weight: bold !important;
}
.swipe-menu-nested {
  padding-left: 2%;
}
.swipe-logo{
  margin-top: 6%;
}
.swipe-menu-icon-settings{
  position:absolute;
  left:0;
  bottom:0;
  right:0;
}
.swipe-box{
display: flex;
  flex-direction: column;
  justify-content: space-between;
  height:100%;
}

/*
MISC
*/
.misc-headerone {
  color: rgba(0,0,0,1) !important;
  font-size: 60px !important;
}
.misc-headerfour {
  color: rgba(123,123,123,1) !important;
  font-size: 17px !important;
  /* width: 86% !important; */
  text-align: justify !important;
}
.misc-card {
  display: "flex" !important;
}

.misc-close-float {
  float: right !important;
}
.misc-details {
  display: flex !important;
  flex-direction: column !important;
  width:100% !important;
}
.misc-content {
  flex: 1 0 auto !important;
}
.misc-unit {
  padding-right: 5px !important;
}
.misc-close-bt {
  border-radius: 50% !important;
/*   background-color: black !important; */
  color: rgba(0, 0, 0, 0.54) !important;
  float: right;
}
.misc-card-parent-grid {
  width: 100% !important;
  padding-top: 4% !important;
}
.misc-spinner-holder {
  display: flex !important;
  align-items: center !important;
  width: 186px !important;
  min-width: 186px !important;
  height: 186px !important;
  justify-content: center !important;
}
.misc-card-fst-child {
  font-weight: bold !important;
  padding-left: 2% !important;
}
.misc-card-snd-child {
  padding-top: 2% !important;
  flex-grow: 0 !important;
  max-width: 233px !important;
  flex-basis: 46% !important;
}
.misc-card-types {
  padding-top: 2% !important;
}
.misc-card-value {
  padding-top: 2% !important;
  flex-grow: 0 !important;
  max-width: 100px !important;
  flex-basis: 20% !important; 
}
.misc-card-activ-dates {
  font-weight: bold !important;
  padding-left: 2% !important;
  padding-top: 2% !important;
}
.misc-add-padd-top-two {
  padding-top: 2% !important;
}
.misc-card-validfrom {
  padding-top: 2% !important;
  flex-grow: 0 !important;
  max-width: 39% !important;
  flex-basis: 39% !important;
  padding-right: 2% !important;

}
.misc-card-validto {
  padding-top: 2% !important;
  flex-grow: 0 !important;
  max-width: 36% !important;
  flex-basis: 36% !important;
}
/*
HOME
*/
.home-root {
  flex-grow: 1;
  min-height: 94vh;
}
.home-body {
  background-color: white;
}
.home-paper {
  /* margin-top: 64px; */
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
  min-height:600px;
}
.property-setting-paper {
  margin-top: 8px;
  flex-direction: column;
  align-items: center;
  min-height:600px;
}
.property-settings-return {
  margin-bottom: 9px !important;
}

.property-settings-header-2 {
  padding-bottom: 0.8% !important
}
.home-avatar {
  margin: 1;
  background-color: yellow
}
.home-submit {
  /* margin: theme.spacing(3, 0, 2) */
  margin: 3 0 2 0;
}
.home-linkIcon {
  /* wrap:"nowrap"; */
  font-size: 20px !important;
  vertical-align: 'middle' !important;
  display: 'inline-flex' !important;
  word-wrap:"nowrap" !important;
}
.home-card {
  display: flex,
 /*  padding: theme.spacing(2, 2,3, 2)  */
}
/* .home-details {
  display: flex;
  flex-direction: column;
  width: 100%;
} */
.home-content {
  flex: 1 0 auto;
}
.home-cover {
  width: 170px;
  height: 170px;
}
/* .home-controls {
  display: flex;
  align-Items: center;
  padding-left:16px;
  padding-bottom: 1.5% !important;
  bottom: 0 !important;
  position: absolute;
}  */
.home-headerOne {
  color: rgba(0,0,0,1)!important;
/*   font-size: 60px !important; */
  font-weight: bold !important;
}
.home-headerTwo {
  color: rgba(123,123,123,1) !important;
/*   font-size: 60px !important; */
}
.home-headerThree {
  color: rgba(0,0,0,1);
  font-size: 22px;
  padding-left: 16;
}
.home-headerfour {
  color: rgba(123,123,123,1) !important;
/*   font-size: 20px !important; */
}
.home-headerfive {
  color: rgba(123,123,123,1) !important;
  /* font-size: 18px !important; */
}
.home-filterProperty {
  margin: 1;
}
.home-filterUType {
  margin: 1;    
}
.home-filterLabel {  
  color: rgba(123,123,123,1) !important;   
  font-size: 12px !important;
}
.home-selectEmpty {
  margin-top: 2 !important
}
.home-main {
  max-width: 100% !important;  
}
.home-margin {
  
}
.home.spinner {

}
.home-view-menu{
  padding-left:3.5%;
}
.home-property-add{
  padding-left: 9%;
}
.home-user-add{
  padding-left: 9%;
}
.home-property-add-par-grid{
  width: 12%;
}

/*
Add new user
*/

.addnew-user-body {
  background-color: white
}
.addnew-user-root {
  margin: 0 !important;
  padding: 16px !important;
}
.addnew-user-close-button {
  position: absolute !important;
  right: 8px !important;
  top: 8px !important;
  color: grey !important;
}
.addnew-user-paper {
  margin-top: 3%;
  display: flex;
  justify-content:center;
  flex-direction: column;
  align-items: flex-start;
}
.addnew-user-avatar {
  margin: 1%;
  background-color: yellow;
}
.addnew-user-form {
  width: 100%;  /* Fix IE 11 issue. */
  margin-top: 1%;
}
.addnew-user-submit {
  margin: 3, 0, 2;
}
.addnew-user-myImage {
  width: 100%;
}
.addnew-user-filterUType {
  min-width: 120px !important;
}
.addnew-user-modalroot {
  height: 300px;
  flex-grow: 1;
  transform: translateZ(0);
/* The position fixed scoping doesn't work in IE 11.
   Disable this demo to preserve the others. */
  @media all and (-ms-high-contrast: none): {
    display: 'none',
  }
}
.addnew-user-modal {
  display: flex;
  padding: 1%;
  align-items: center;
  justify-content: center;
}
.addnew-user-modalpaper {
  width: 400px;
  background-color: white;
  box-shadow: 5;
  padding: 2, 4, 3;
}
.addnew-user-modlheaddiv {
  width:100%;
}
.addnew-user-modlheadtilte {
  color: white;
}
.addnew-user-newpasswordmargin {
  margin-top: 3%;
}
.addnew-user-currentpasswordmargin {
  margin-top: 2%;
}
.addnew-user-dialog-title {
  padding-left: "24px"
}
.addnew-user-dialog-content {
  padding: 8px 32px !important;
}
.addnew-user-dialog-actions {
  padding: 8px 24px !important;
}
.addnew-div {
  padding: 20px 0px 40px 0px !important;
}
.addnew-user-loader-clickbt {
  background-color: 'transparent';
  padding: "10px 30px 10px 0px";
}
.addnew-user-card1 {
  display: flex !important;
  max-width: 215px !important;
  max-height: 215px !important;
  margin-right: auto !important;
}
.addnew-user-details {
  display: flex !important;
}
/*
Biller
*/

.biller-root {
  flex-grow:1 !important;
  min-height: 94vh !important;
}
.biller-main {
  max-width: 100% !important;
}
.biller-paper {
  margin-top: 64px !important;
  min-height: 600px !important;
  align-items: center !important;
  flex-direction: column !important;
}
.biller-view-trans-tooltipText {
  word-wrap:nowrap;
  float:left !important;
  padding-right: 9px !important;
  vertical-align: middle;
  display: inline-flex;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.78) !important; 
  font-size: 0.875rem !important;
  }
.biller-miscPaper {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 400;
}
.biller-paper {
  margin-top: 3%;
  flex-direction: column;
  align-items: center;
}
.biller-miscUnit {
  padding-right: 5px;
}
.biller-avatar {
  margin: 1%;
  /* background-color: theme.palette.secondary.main; */
}
.biller-submit {
  margin: 3, 0, 2;
}
.home-card {
  display: flex !important;
}
.biller-vacancycard {
  display: flex;
  color: rgba(0,0,0,1);
}
.home-details {
  display: flex;
  flex-direction: column;
  width:100%;
  justify-content: center;
}
/* .home-content {
  flex: 1 0 auto !important;
} */
.home-cover {
  width: 170px !important;
  height: 170px !important;
}
.home-controls {
  display: flex !important;
  align-items: center !important;
  padding-left: 16px;
  padding-top: 1.5%;
}
.biller-headerOne {
  color: rgba(0,0,0,1) !important;
/*   font-size: 60px !important; */
}
.biller-paddingtop-1p5p {
  padding-top: 1.5% !important;
}
.biller-fontweight {
  font-weight: 500 !important;
}
.biller-headerTwo {
  color: rgba(123,123,123,1) !important;
  font-size: 60px !important;
}
.biller-headerThree {
  color: rgba(0,0,0,1) !important;
  font-size: 18px !important;
}
.biller-headerfour {
  color: rgba(123,123,123,1) !important;
/*   font-size: 17px !important */;
}
.biller-headerfive {
  color: rgba(123,123,123,1) !important;
  font-size: 12px !important;
}
.biller-shareIcon {
  margin-top:4%;
  float: left;
  width:7%;
}
.biller-importIcon {
  margin-top:4%;
  float: left;
  width:7%;
}
.biller-shareLabel {
  width: 39%;
  float: left;
  margin-top: 5%;
  padding-left: 4%;
}
.biller-shareLoader {
  width: 6% !important;
  height: 6% !important;
  float: left;
  margin-top: 6%;
  color: #afb5b6;
}
.biller-filterProperty {
  margin: 1%;
  min-width: 220px;
  width: 564px;
}
.biller-filterUType {
  margin: 8px !important;
  min-width: 120px !important;
  float:left !important;
}
.biller-filterLabel {
  color: rgba(123,123,123,1) !important;
  float: left !important;
  margin: 8px !important;
  margin-top: 8px !important;
  font-size: 17px !important;
  min-width: 56px !important;
  margin-top: 32px !important;
}
.biller-filteerLavel-grd{
  max-width: max-content !important;
}
.biller-vacancy-paper {
  color: rgba(0,0,0,1) !important;
  font-size: 18px !important;
}
.biller-selectEmpty {
  margin-top: 2%;
} 
.biller-wrapper {
  margin: 1% !important;
  position: relative !important;
}
.biller-fabProgress {
  color: #afb5b6;
  position: absolute;
  top: 35px;
  left: 465px;
  z-Index: 1;
}
.biller-tabRoot {
  flex-grow: 1;
  width: 100%;
  /* background-color: theme.palette.background.paper; */
}
.biller-closeButton {
  position: absolute;
  right: 5%;
  top: 5%;
 /*  color: theme.palette.grey[500]; */
}
.biller-OccuPaper {
  padding: 8px;
  margin: auto;
}
.biller-lease-activity-bt {
  border-radius: 25px !important;
  padding: 7px 20px  !important;
  background-color:#d3dfea !important;
  color: #3f27ec !important;
  border-color: #3f27ec !important;
  width: 200px !important;
}
.biller-lease-activity-tf > div {
  border-radius: 25px !important;
  padding: 7px 20px  !important;
  background-color:rgba(63, 0, 223, 0.12) !important;
  color: #6100ED !important;
  width: 200px !important;
  height: 32px !important;
  border: 1px solid rgba(98, 0, 238, 1) !important;
  font-size: 14px !important;
}
.biller-lease-activity-tf-dsbl > div {
  border-radius: 25px !important;
  padding: 7px 20px !important;
  background-color: rgba(255, 255, 255, 1) !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
  border:none !important;
  width: 200px !important;
  height: 32px !important;
  color: rgba(0, 0, 0, 0.50) !important;
  font-size: 14px !important;
  }
.biller-lease-edit-bt {
  padding: 7px 20px !important;
  background-color:#9eecb6 !important;
  border-color: #c7cacc !important;
  color: #402f19 !important;
}
.biller-edit-icon {
  border-radius: 50% !important;
  height:54px !important;
  min-width: 54px !important;
}

.biller-edit-icon-rect {
  height:54px !important;
  min-width: 54px !important;
  background-color: #F8F8F8 !important;
  text-transform: none !important;
  height: 30px !important;
  width: 85px !important;
}

.csvexport-fnt {
  font-size: .8rem !important;
  line-height: 1.2rem !important;
}

.csvexport-icon {
  opacity: 0.77 !important;
  margin-right: 6px !important;
}

/*
Add Lease
*/
.addlease-main {
  max-width: 100% !important;
}
.addlease-root {
  flex-grow: 1;
}
.addlease-paper {
  margin-top: 8px;
  flex-direction: column;
  align-items: center;
  min-height:600px;
}
.addlease-headerfour {
  color: rgba(123,123,123,1) !important;
/*   font-size: 17px !important; */
}
.addlease-headertwo {
  color: rgb(158, 149, 149) !important;
  font-size: 60px !important;
}
.addlease-headerone {
  color: (0,0,0,1) !important;
/*   font-size: 60px !important; */
}
.addlease-header-return-menu {
  color: #9e9595;
  font-size: 20px !important;
}
.addlease-same-line-return-menu {
  float:right;
}
.addlease-return-menu-par {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.addlease-same-line-return-menu li {
  display:inline-block;
  font-size: 1.5em;
  text-align: right;
}
.addlease-same-line-return-menu ul{
  margin: 0;
}
.addlease-headerfour {
  color: rgba(123,123,123,1);
/*   font-size: 17px; */
}
.addlease-unit-field {
  padding-left: 0.5% !important;
  padding-top: 2% !important;
  padding-bottom: 1% !important;
}
.addlease-rent-field {
  width: 83%;
}
.addlease-security-field {
  width: 83%;
}
.addlease-details {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.addlease-hidden-bt {
  width: 90%;
  border-radius: 4px !important;
  padding: 7px 20px  !important;
  background-color:#ffffff !important; 
  visibility:hidden !important;
}

.addlease-activity-bt {
  width: 100%;
  border-radius: 4px !important;
  padding: 7px 20px  !important;
  background-color:#ffffff !important;
  /* background-color: #d3dfea !important; */
  color: #6100ed !important;
  border: 1px solid rgba(0, 0, 0, .1) !important;
  /* border: 1px solid rgba(63, 39, 236, 1) !important; */
  box-shadow: none !important;
}
.addlease-addtenant-inner {
  width: 100%;
}
.addlease-addtenant-inner-card { 
  width: 100% !important;
  display: "flex" !important;;
  min-height: 400px !important;;
  padding-bottom: 2% !important;
}
.add-lease-addtenant-card-inner-grid {  
  width: 100% !important;
  padding-top: 6%; 
}
.add-lease-addtenant-table-inner-grid {
  padding-top: 30px !important;  
}

.addlease-rightcard-inner-card { 
  width: 100%;
  display: "flex";
  overflow: hidden;
}
.addlease-bottomcard-inner-card { 
  width: 100%;
  display: "flex";
}

.add-lease-addtenant-rightcard-inner-grid {
  padding-bottom: 32px;  
  padding-left: 2%;
  padding-right: 2%;
  overflow: none;
}
.add-lease-addtenant-bottomcard-inner-grid {
  padding-bottom: 56px;
  width: 100% !important;
  padding-top: 2%;
  padding-left: 2%;
  padding-right: 2%;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.addlease-right-fields-p {
  padding-top: 5%;
}
 
.addlease-tenantlist-div {
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
}
.addlease-tenantlist-each {
  width: 100%;
}
.addlease-flex-container {
  display: flex;
}
.addlease-flex-container > div {
  font-size: 1rem;
  padding-bottom: 1%;
  width: 25%;
  padding-left: 3%;
}
.addlease-flex-container > button {
  font-size: 22px;
  width: auto!important;
  padding-left: 3%;
  font-size: 100% !important;
}
.addlease-top-flex-container {
  display: flex;
}
.addlease-tenantadd-flex-container {
  display: flex;
}
.addlease-tenantadd-flex-container > div {
  font-size: 18px;
  padding-top: 3%;
}
.addlease-top-flex-container > div {
  font-size: 18px;
  padding-top: 3%;
}
.addlease-add-misc-bt {
  width: auto;
  border-radius: 4px !important;
  padding: 7px 20px  !important;
/*   background-color:#ffffff !important; */
  background-color: #ffffff !important;
  color: #6100ed !important;
/*   border: 1px solid #BBB7B7 !important; */
  border: 1px solid rgba(0, 0, 0, .1) !important;
  box-shadow: none !important;
  
}
.addlease-add-misc {
  padding-top: 2% !important;;
}
/* .addlease-returnto-rent-arrow {
  color:
} */
.addlease-manualtenant-header {
  font-size: 2.4rem !important;
  float: left !important;
}
.addlease-misc-header {
/*   font-size: 2.4rem !important; */
  float: left !important;
  padding-right: 15% !important;
}
.addlease-addtenant-header {
/*   font-size: 2.4rem !important; */
  color: black !important;
  float: left !important;
}
.addlease-blanktenant-icon {
  width: 20% !important;
  height: 46% !important;
  color: #e9eef2 !important;
  left: 40% !important;
  position: relative !important;
}
.addlease-blanktenant-holder {
  position: relative !important;
  left: 41% !important;
}
.addlease-to-managerent-lbl {
  line-height: 1.4 !important;;
}
.addLease-tenantlist-card {
  box-shadow: none !important;
} 
.addLease-misc-edit-icon {
  border-radius: 50% !important;
  height:54px !important;
  min-width: 54px !important;
  border: none !important;
}
.addlease-misc-label {
  padding-top: 2.8% !important;
}
.makepayment-property-header {
  color: #7c7c7c !important;
}

.addLease-misc-edit {
  padding-top: 0.8% !important;
}

/*
Add tenant
*/
.addtenant-firstname {
  width: 100% !important;
}
.addtenant-lastname{
  width: 100% !important;
}
.addtenant-mobnumber{
  width: 100% !important;
}
.addtenant-email{
  width: 100% !important;
}
.addTenant-DialogActions {
  justify-content: flex-start !important;
  padding: 8px 24px 16px 24px !important;
}
.addTenant-closebt{
  float: right !important;
}
.addtenant-dialog-content {
  border-top: none !important;
  will-change: transform !important;
}
.addtenant-grid{
    padding-top: 15px !important;
    padding-bottom: 25px !important;
}
 
/*
List Tenant
*/
.listtenant-rentCard {
  display: flex;
  width: 100%;   
  border: 1px solid #DADADA;
  border-radius: 5px;
}


/*
Change Email
*/
.change-email-root {
  display: flex;
  flex-wrap: wrap;
}
.change-email-paper {
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.change-email-form {
  width: 100%;
  margin-top: 3%;
}
.change-email-submit {
  margin: 3, 0, 2;
}
.change-email-miButtonroot {
  border-radius: 0px;
  background-color: yellow;
  color: black;
}
.change-email-muiButtoncontained {
  box-shadow: none;
}
.change-email-myImage {
  width: 100%;
}
.change-email-pageSecondHeader {
  font-size: 17 / fontScale;
  text-align: center;
  font-weight: 401px;
}
.change-email-headerGrid {
  width: 100%;
}
.change-email-margin {
  margin-top: 3%;
}
.change-email-withoutLabel {
  margin-top: 3%;
}
.change-email-textField {
  width: 200px;
}

/*
Forgot password
*/

.forgot-password-root{
  display: flex;
  flex-wrap: wrap;
}

.forgot-password-reset-password {
/*   color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em; */
}
.forgot-password-paper{
  display: flex !important;
  margin-top: 64px !important;
  align-items: center !important;
  flex-direction: column !important;
}
.forgot-password-logo{
  width: 100% !important;
}
.forgot-password-header-label{
  width: 100% !important;
}
.forgot-pass-header { 
  padding: 40px 0 40px 0 !important;
  text-transform: uppercase !important;
  white-space: nowrap !important; 
  font-family: "Helvetica Neue" !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 20px !important;
  color: rgba(0,0,0,1) !important;
  text-align: center !important;
} 

.forgot-password-form{
  width: 100% !important;
  margin-top: 24px !important;
}
.forgot-password-submit{
  padding: 40px 0px 40px !important;
}

/*
Loader Button
*/
.loader-button-submit{
  margin: 3, 0, 2;
}

/*
Menu App bar
*/

.menubar-root{
  background-color: #cccccc;
  flex-grow: 1;
}
.menubar-menuButton{
  margin-right: 2%;
}
.menubar-title{
  flex-grow: 1;
}
.menubar-moreVert{
  color:#fff;
}

/*
Multicombo
*/
.multicombo-paper{
  margin-top: 3%;
  display: flex;
  justify-content:center;
  flex-direction: column;
  align-items: flex-start;
}
.multicombo-avatar{
  margin: 1%;
 /*  backgroundColor: theme.palette.secondary.main */
}
.multicombo-form{
  width: 100%;
  margin-top: 3%;
}
.multicombo-submit{
  margin: 3, 0, 2;
}
.multicombo-myImage{
  width: 100%;
}

/*
Renter
*/

.renter-root {
  flex-grow: 1 !important;
  min-height: 94vh !important;
}
.renter-paper {
  margin-top: 3%;
  flex-direction: column;
  align-items: center;
}
.renter-avatar {
  margin: 1%;
/*   background-color: theme.palette.secondary.main; */
}
.renter-submit {
  margin: 3, 0, 2;
}
.home-card {
  display: flex !important;
}
.renter-details {
  display: flex;
  flex-direction: column;
  width: 100%;   
  justify-content: center;
}
.renter-content {
  flex: 1 0 auto !important;
}
.home-cover {
  width: 170px !important;
  height: 170px !important;
}
.renter-controls {
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-top: 1.5%;
}
.renter-headerOne {
  color: rgba(0,0,0,1)!important; 
}
.renter-headerTwo {
  color: rgba(123,123,123,1); 
}
.renter-headerThree {
  color: rgba(0,0,0,1);
  font-size: 22px;
}
.renter-headerfour {
  color: rgba(123,123,123,1); 
}
.renter-headerfour-up {
  color: rgba(123,123,123,1) !important; 
}
.renter-headerFive {
  color: #7B7B7B;  
}
.renter-headerSix {
  color: rgba(0,0,0,.6);    
}
.renter-headerSeven {
  color: #7c7c7c; 
}
.renter-thankYouHFour {
  color: rgba(0,0,0,1);
  border-left: 6px solid #ffe100;
  padding-left: 7px;
}
.renter-filterProperty {
  margin: 8px!important;
  min-width: 220!important;
  width: 564px!important;
}

.renter-margintop-3d8p {
  margin-top: 3.8% !important;
}

.renter-filterUType {
  margin: 8px!important;
  min-width: 120px!important;
  float:left!important;
}
.renter-filterLabel {
  margin: 8px!important;
  margin-top: 32px!important;
  width: 56px!important;
  color: rgba(123,123,123,1)!important;
  font-size: 17px!important;
  float:left !important;
}
.renter-selectEmpty {
  margin-top: 2%;
}
.renter-papers {
  padding:2%;
  /* color: theme.palette.text.secondary */
}
.home-cards {
  display: flex;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.renter-rentCardClick {
  display: flex;
  width: 100%;
  background:#3f51b52e;
  border: 1px solid #3f51b5;
  border-radius: 5px;
  box-shadow: none;
}
.renter-rentCard {
  display: flex;
  width: 100%;
  border: 1px solid #DADADA;
  border-radius: 5px;
}
.renter-f {
  display: flex;
  width: 00%;
  border: 1px solid #DADADA;
  border-radius: 5px;
  box-shadow: none;
}
.renter-rentCardSelect {
  display: flex;
  width: 100%;
  border: 1px solid #6200EE;
  border-radius: 5px;
}
.renter-form {
  width: 100%;
  margin-top: 24;
}   
/* .renter-expand {
  
} */
.renter-expandOpen {
  transform: rotate(180deg);
}
.renter-payImg {
  width: 100%;
  height: 100%;
}
.renter-payCardCont {
  padding: 8px;
}
.renter-main {
  max-width: 100% !important;
}
.renter-transpage-fee {
  width:100% !important;
}
.successIcon {
  border: 1px solid green!important;
  color: 'green'!important;
}
.errorIcon {
  border: 1px solid red!important;
  color: 'red'!important;
}
.footer {
 /*  position:absolute; */
  bottom:0;
  width:100%;
  height:40px;   /* Height of the footer */ 
}
.footer-wtbg{
  /* position:relative; */
  bottom:0;
  width:100%;
  height:40px;   /* Height of the footer */
  background:#F8F8F8 !important;
}
 
.policy-cls{ 
  width: 99%;   
  padding-bottom: 25px;
}
.policy-cls p {
  font-size: 12px;
  font-weight: bold;
  color:#707070;
}
.policy-cls-rg{ 
    padding-bottom: 25px;
    width: 100%; 
}
.policy-cls-rg p {
  font-size: 12px;
  font-weight: bold;
  color:#707070;
}

 .stripe-key-card {
  display: flex !important;;
  width: 100% !important;
  border: 1px solid #DADADA !important;
  border-radius: 5px !important;
}
.stripe-key-card-content {
  width: 100% !important;
}

.tenant-grid-tf > div {
  border-radius: 25px !important;
 /*  padding: 7px 20px  !important; */
  background-color:rgba(63, 0, 223, 0.12) !important;
  color: #6100ED !important;
  width: 200px !important;
  height: 32px !important;
  border: 1px solid rgba(98, 0, 238, 1) !important;
  font-size: 14px !important;
}
.tenant-grid-tf-dsbl > div {
  border-radius: 25px !important; 
  background-color: rgba(255, 255, 255, 1) !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
  border:none !important; 
  height: 32px !important;
  color: rgba(0, 0, 0, 0.50) !important;
  font-size: 14px !important;
  }
.team-grid-tf > div {
    border-radius: 25px !important;  
    background-color:rgba(63, 0, 223, 0.12) !important;
    color: #6100ED !important;
    width: 60px !important;
    height: 32px !important;
    border: 1px solid rgba(98, 0, 238, 1) !important; 
    font-size: 14px !important;
  }

  .policy-paper{
    margin-top: 64px;
    display: "flex";
    flex-direction: "column";
    align-items: "center";
  }

  .policy-paper h2 {
      font-size: 27px;
  }
  .policy-paper h3 {
    font-size: 13.5px;
}
  .policy-paper p {
    margin-block-start:0px;
    font-size: 12px;
  }
  .policy-paper ol {
    list-style: none;
    padding-left: 0;
  }
  .policy-paper ul {
    list-style-position: outside;
    padding-left: 0;
    list-style: disc;
  }
  .signin-main {
    position: fixed;
    top: 50%;
    left: 50%;    
    transform: translate(-50%, -50%);
  }
  .rent-header-pad{
     padding-bottom:56px;
  }
.signup-paper {
  display: flex !important;
  margin-top: 64px !important;
  align-items: center !important;
  margin-bottom: 64px !important;
  flex-direction: column !important;
}
.signup-myimage {
  width: 100%;
}
.signup-form {
  width: 100%;
  margin-top: 8px;
}
.signup-header {  
  padding: 35px 0 35px 0 !important;
  white-space: nowrap !important;
  text-align: left !important;
  font-family: "Helvetica Neue" !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 20px !important;
  color: rgba(0,0,0,1) !important;
}
/*
Skelton
*/

.skelton-action {
    width: 48px;
    border-radius: 50% !important;
    height: 82px !important;
    padding: 12px !important;
}
.skelton-action-parent-div {
  display: flex !important;
}
.skelton-grid-element {
    height:69px !important; 
}

.skelton-grid-element-custom {
  width:251px !important; 
}

.skelton-grid-element-custom-100 {
  width:100% !important; 
}

.skelton-property-page-header {
	width: 39% !important;
	height: 82px !important;
}

.skelton-property-page-fab {
	width: 44px !important;;
	height: 70px !important;;
}
.skelton-margin-right-5 {
  margin-right: 5px !important;
}

.skelton-margin-bottom-4 {
  margin-bottom: 4px !important;
}

.skelton-margin-right-6 {
  margin-right: 6px !important;
}

.skelton-margin-right-14 {
  margin-right: 14px !important;
}

.skelton-filter-cus {
  margin: 8px !important;
}

.skelton-margin-right-19 {
  margin-right: 19px !important;
}

.skelton-property-page-flex-compo-grid {
    display: flex !important;
}

.skelton-user-page-flex-compo-grid {
  display: flex !important;
  float: right !important;
}

.skelton-property-page-flex-compo-grid-csv {
  display: flex !important;
  float: right !important;
}

.skelton-viewtrans-page-flex-compo-grid-csv {
  display: flex !important;
  float: right !important;
}

.skelton-flex-parent {
  display: flex !important;
}

.child-dfloat-left {
  float: left !important;
}

.skelton-padding-0008 {
  padding: 0px 0px 0px 8px !important;
}

.skelton-card-hover:hover {
    background-color: "#FFF" !important;
}

.csv-export-link {
  text-decoration: none !important;
  color: black !important;
  font-weight: 500 !important;
  transition: 200ms !important;
}
.csv-export-link-disabled {
  text-decoration: none !important;
  color: #909199 !important;
  font-weight: 500 !important;
  transition: 200ms !important;
}

.csv-export-link:hover {
  color: #d1cfcf !important;
}

.csv-export-link-disabled:hover {
  color: #d1cfcf !important;
}

.csv-export {
  display: flex !important;
  transition: 200ms !important;
  border-radius: 5px;
}
.csv-export:hover {
  color: #909199 !important;
  cursor: pointer !important;
}

.gridfilter-innersearch-bt:hover {
  cursor: pointer !important;
}

.csv-export-disabled {
  display: flex !important;
  transition: 200ms !important;
  border-radius: 5px;
}
.csv-export-disabled:hover {
  color: #909199 !important;
  cursor: wait !important;
}

.csv-export-text-pad-top-1p {
  padding-top: 1% !important;
}

.skelton-credit-card-list {
  height: 62px !important;
}
.skelton-view-trans-filter {
  position: relative !important;
  height: 59px !important;
  width: 63px !important;
}

.skelton-billing-history-filter-par {
  position: relative !important;
  height: 54px !important;
  width: 63px !important;
}

.skelton-manage-list-card {
  display: flex;
  width: 100%;
  border-radius: 5px;
}

.skelton-billing-history-filter {
  float: left !important;
}

.skelton-float-left {
  float: left !important;
}

.skelton-billing-history-filter-txt {
  position: absolute !important;
  bottom: 0 !important;

}

.skelton-make-payment-rent-cards {
  display: flex !important;
  width: 100% !important;
  border: 1px solid #DADADA !important;
  border-radius: 5px !important;
}

.skelton-pad-8 {
  padding: 8px !important;
}

.skelton-max-width-407 {
  max-width: 407px !important;
}

.skelton-min-width-400 {
  min-width: 400px !important;
}

.skelton-max-width-121 {
  max-width: 121px !important;
}

.skelton-min-width-121 {
  min-width: 121px !important;
}

.skelton-max-width-190 {
  max-width: 190px !important;
}

.skelton-min-width-190 {
  min-width: 190px !important;
}

.skelton-max-width-259 {
  max-width: 259px !important;
}

.skelton-min-width-259 {
  min-width: 259px !important;
}

.skelton-min-width-200 {
  min-width: 200px !important;
}

.skelton-max-width-200 {
  max-width: 200px !important;
}

.skelton-min-width-380 {
  min-width: 380px !important;
}

.skelton-max-width-408 {
  max-width: 408px !important;
}

.skelton-max-width-497 {
  min-width: 497px !important;
}

.skelton-max-width-389 {
  min-width: 389px !important;
}

.skelton-width-p100 {
  width: 100% !important;
}

.skelton-width-p58 {
  width: 58% !important;
}

.skelton-width-p45 {
  width: 45% !important;
}

.skelton-height-p38 {
  height: 38% !important;
}

.skelton-txt-filter-viewtrans {
  position: absolute !important;
  bottom: 11% !important;
}

.skelton-width-p40 {
  width: 40% !important;
}

.skelton-width-p25 {
  max-width: 25% !important;
}

.skelton-margin-r-25 {
  margin-right: 25px !important;
}

.skelton-minwidth-212 {
  min-width: 35% !important;
}

.skelton-minwidth-fld {
  min-width: 240px !important;
}

.skelton-paddingleft-14 {
  padding-left: 14px !important;
}

.skelton-width-50p {
  width: 50% !important
}

.skelton-margintop-7p {
  margin-top: 7% !important;
}

.skelton-margintop-5p {
  margin-top: 5% !important;
}

.skelton-margintop-2p {
  margin-top: 2% !important;
}

.property-table-dyn-show {
  display: block !important;
}
.property-table-dyn-hide {
  display: none !important;
}

.property-table-hide {
  padding: 20px 0px 40px 0px !important;
  display: none !important; 
}

.property-table-show {
  padding: 20px 0px 40px 0px !important;
  display: block !important; 
}

.managment-header {  
  width: 100%;
  text-transform: uppercase !important;
  white-space: nowrap !important;
  text-align: center !important;
  font-family: "Helvetica Neue" !important;
  font-style: normal !important;
  font-weight: bold !important;
/*   font-size: 39px !important; */
  color: rgba(0,0,0,1) !important;
}

.tenant-table-hide {
  padding: 20px 0px 40px 0px !important;
  display: none !important; 
}

.tenant-table-show {
  padding: 20px 0px 40px 0px !important;
  display: block !important; 
}
.viewtrans-table-hide {
  padding: 20px 0px 40px 0px !important;
  display: none !important; 
}

.viewtrans-table-show {
  padding: 20px 0px 40px 0px !important;
  display: block !important; 
}

.managerent-table-hide {
  padding: 20px 0px 40px 0px !important;
  display: none !important; 
}

.managerent-table-show {
  padding: 20px 0px 40px 0px !important;
  display: block !important; 
}


.powered-by-txt { 
  overflow: visible !important;
  width: 64px !important;
  white-space: nowrap !important;
  text-align: left !important;
  font-family: "Helvetica Neue" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  color: rgba(112,112,112,1) !important;
}

.powered-by-logo { 
  overflow: visible !important;
  width: 137px !important;
  height: 27px !important;
}

.verify-user-header { 
    padding: 35px 0 35px 0 !important;
    text-transform: uppercase !important;
		white-space: nowrap !important;
		text-align: center !important;
		font-family: "Helvetica Neue" !important;
		font-style: normal !important;
		font-weight: bold !important; 
		color: rgba(0,0,0,1) !important;
}

.offline-payment-labels {
  color: black !important;
  font-weight: 500 !important;
  padding: 1px 1px 25px 1px !important;
}

.offline-width-100p {
  width: 100% !important;
}

.offline-height-lb-56px {
  max-height: 56px !important;
}

.offline-bt-flex {
  display: flex !important;
}

.offline-bt-padding-15p {
  padding-left: 30px !important;
}

.offline-modal-bt {
  padding-top: 2% !important;
  padding-bottom: 3% !important;
}

.offline-modal-header {
  line-height: 1.3 !important;
}

.loader-delete-bt {
  background-color: white !important;
}

.view-trans-editoffline-enb {
  display: block !important;
}

.view-trans-editoffline-dsb {
  display: none !important;
}

.add-margintop-p8p {
  margin-top: .8% !important;
}

.add-padding-p8p {
  padding-bottom: 0.8% !important;
}

.add-marginbottom-1d2p {
  margin-bottom: 1.2% !important;
}

.addlease-cardsvg {
	color: rgb(0, 0, 0) !important;
	top: 0% !important;
	left: 30% !important;
	position: relative !important;
	width: 25% !important;
	height: 25% !important;
	opacity: 0.1 !important;
}

.makepayment-gatewaysvg {
	color: rgb(0, 0, 0) !important;
  width: 3% !important;
  height: 3% !important;
  min-width: 44px !important;
  min-height: 44px !important;
}

.makepayment-footer-1 {
  color: #7c7c7c !important;
  font-size: 10pt !important;
  font-weight: bold !important;
  font-family: "Arial" !important;
  padding-bottom: .3% !important;
}

.makepayment-footer-2 {
  color: #7c7c7c !important;
  font-size: 10pt !important;
  font-family: "Arial" !important;
  padding-bottom: .3% !important;
}

.makepayment-footer-3 {
  color: #7c7c7c !important;
  font-size: 10pt !important;
  font-family: "Arial" !important;
}

.makepayment-paddingleft-1p {
  margin-left: 1% !important
}
.addlease-relative {
  position: relative !important;
}

.fontweight-bold {
  font-weight: bold !important;
}

.fontweight-401 {
  /* font-weight: 407 !important; */
}

.secure-icon-payment {
  font-size: 1.2rem !important;
  margin-right: 6px !important;
}

.addcreditcard-topicons {

}

.addcreditcard-marginleft3d5p {
  margin-right: 3.5% !important;
}

.addcreditcard-nameOnCard {

}

.addcreditcard-width100p {
width: 100% !important;
}

.addcreditcard-DialogActions {
  justify-content: flex-start !important;
  padding-left: 24px !important;
}

.addcreditcard-modal-bt {
  padding-top: 3% !important;
  padding-bottom: 4.5% !important;
}

.addcreditcard-payment-labels {
  color: black !important;
  padding: 0px 0px 10px 0px !important;
  color: #9d9a9a !important;
}

.addcreditcard-payment-labels-disbl {
  color: black !important;
  padding: 0px 0px 10px 0px !important;
  color: white !important;
}

.addcreditcard-select-placeholder {
  color: #9d9a9a !important;
}

.addcreditcard-select-placeholder::placeholder{
  color: #9d9a9a !important;
}

.addcreditcard-skelton-marginbottom-1p {
  margin-bottom: 1% !important;
}

.addcreditcard-skelton-minwidth {
  min-width: 290px !important;
}

.addpayment-deposit-label-dsbl{
  color: #9d9a9a !important;
}

.addpayment-deposit-tooltip {
  margin-top: -1% !important;
  margin-left: 2% !important;
}

.addpayment-margintop-5p {
  margin-top: 5% !important;
}

.addpyment-unit {
  font-weight: bold !important;
}

.mainpropertylist-header {
  display: "flex" !important;
  flex-direction: "column" !important;
  align-items: "center" !important;
  text-transform: capitalize !important;
}

.mainpropertylist-paper {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 54px !important;
  flex-direction: column !important;
}

.mainpropertylist-header {  
  padding-top: 35px !important;
  white-space: nowrap !important;
  text-align: center !important;
  font-family: "Helvetica Neue" !important;
  font-style: normal !important;
  font-weight: bold !important;
/*   font-size: 20px !important; */
  color: rgba(0,0,0,1) !important;
}

.mainpropertylist-managment-header {  
  width: 100%;
  text-transform: uppercase !important;
  white-space: nowrap !important;
  text-align: center !important;
  font-family: "Helvetica Neue" !important;
  font-style: normal !important;
  font-weight: bold !important;
/*   font-size: 39px !important; */
  color: rgba(0,0,0,1) !important;
}

.mainpropertylist-card {
  display: flex !important;
}

.mainpropertylist-details {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;  
}

.mainpropertylist-content {
  flex: 1 0 auto !important;
}

.mainpropertylist-width100p {
  width: 100% !important;
}

.mainpropertylist-maxwidth100p {
  max-width: 100% !important;
}

.mainpropertylist-paddingtop7p {
  padding-top: 7% !important;
}
.mainpropertylist-paddingtop37p{
  padding-bottom: 37% !important;
}
.mainpropertylist-paddingtop3p {
  padding-top: 3% !important;
}
.mainpropertylist-short-divider {
  margin-top: 3% !important;
  width: 3% !important;
  height: 3px !important;
  color: #707070 !important;
}
.mainpropertylist-add-text {
  float: left !important;
  padding-top: .5% !important;
  padding-left: 1% !important;
  color: rgba(0,0,0,1) !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  cursor:pointer !important;
}
.mainpropertylist-add-fab {
  float: left !important;
}
.mainpropertylist-paddingtop3d5p {
  padding-top: 3.5% !important;
}
.mainpropertylist-settings-text {
  padding: 7px 20px  !important;
  color: #6200EE !important;
  font-weight: 500 !important;
}
.mainpropertylist-paddingtop1d5p {
  padding-top: 1.5% !important;
}
.mainpropertylist-paddingbottom1p {
  margin-bottom: 1% !important;
}
.mainpropertylist-Modal-grid{
  padding-top: 15px !important;
  padding-bottom: 10px !important;
}

.fontweight-500 {
  font-weight: 500 !important;
}

.dialog-bt-paddingbottom-8p {
  padding-bottom: 8% !important;
}

.property-settings-row {
  display: flex; /* equal height of the children */
}

.property-settings-col {
  flex: 1; /* additionally, equal width */
}

.property-settings-card-h100 {
  height: 100% !important;
}

.add-teammember-tag {
  cursor:pointer !important;
}

.add-tenant-tag {
  cursor:pointer !important;
}

.add-teammember-margin-top-p7p {
  margin-top: .7% !important;
}

.add-lease-tag {
  cursor:pointer !important;
}

.add-payment-tag {
  cursor:pointer !important;
}

.appbar-popper {
	position: absolute !important;
	will-change: transform !important;
	top: 7.5% !important;
	left: 4% !important;
	transform: none !important; 

}

.appbar-popper-plus-icon {
  width: 0.8em !important;
  height: 0.8em !important;
}

.appbar-addnewproperty-padding-top-3p {
  padding-top: 3% !important;
}

.add-tenant-edit-marginleft-40p {
  margin-left: 40% !important;
}

.appbar-popper-li-maxw {
  min-width: 26px !important;
}

.viewtrans-skelton-combo {
  margin-left: 1.5%  !important;
  min-width: 675px !important;
}


.card-listing-f1 {
  padding-bottom: 1% !important;
}
/* Global Settings */

.global-settings-header {
  padding-bottom: 80px !important

}

.dialog-paper {
  max-width: 750px !important;
  padding-bottom: 8px !important
}
.delete-dialog-title {
  padding-left: 24px !important;
}
.reponsive-hide-text {
  display: none !important;
}

.homemenu-headerfour {
  color: #000000 !important;
}
.forgot-user-header { 
  padding: 35px 0 35px 0 !important; 
  white-space: nowrap !important;
  text-align: center !important;
  font-family: "Helvetica Neue" !important;
  font-style: normal !important;
  font-weight: bold !important; 
  color: rgba(0,0,0,1) !important;
}
.forgot-user-body {
  padding: 11px 0 35px 0 !important; 
  white-space: nowrap !important;
  text-align: center !important;
  font-family: "Helvetica Neue" !important;
  font-style: normal !important; 
  color: "#707070"!important;
}

.forgot-user-icon{
  margin: 54px 0px 0px; 
  border-top-style: groove;
  border-top-color: #707070;
  border-top-width: 1px;
  padding-top: 64px;
}

.page-content-header { 
  padding: 35px 0 35px 0 !important; 
  white-space: nowrap !important;
  text-align: center !important;
  font-family: "Helvetica Neue" !important;
  font-style: normal !important;
  font-weight: bold !important; 
  color: rgba(0,0,0,1) !important;
}
.page-content-body {
  padding: 11px 0 35px 0 !important;   
  text-align: center !important;
  font-family: "Helvetica Neue" !important;
  font-style: normal !important; 
  color: "#707070"!important;
}

.page-content-devider{  
  margin: 54px 0px 0px; 
  border-top-style: groove;
  border-top-color: #707070;
  border-top-width: 1px;
  padding-top: 64px;
}

.page-content-underline{
  margin: 35px 0px 0px; 
  border-top-style: groove;
  border-top-color: #707070;
  border-top-width: 1px; 
  padding-top: 34px;  
  margin-right: auto;
  margin-left: auto;
}
.page-content-underline-margin{
  margin: 14px 0px 0px; 
  padding-top: 30px;  
}

.addpayment-security-lable {
  color: #898989 !important;
  font-weight: 500 !important;
  padding-top: 20% !important;
}
.addpayment-special-labels-1 {
  color: #9d9a9a !important;
  font-weight: 500 !important;
  padding: 1px 1px 25px 1px !important;
  font-size: 1.875rem !important;
}
.addpayment-special-labels-2 {
  color: black !important;
  font-weight: 500 !important;
  padding: 1px 1px 25px 1px !important;
  font-size: 1.875rem !important;
}
.hiddenfields-1 {
  visibility:hidden !important;
}
.formlabel-weight .MuiFormControlLabel-label {
  font-weight: 500 !important;
}

.main-logo {
  display: block;
  width: 100% !important;
  height: 100% !important;
}

.addpayment-modal-lastrow-col {
  height: 130px !important;
}

.makepayment-rent-card-calander {
  padding-top: 8%;
}

.renter-rentamount-date {
  color: rgba(0,0,0,.6);
  font-weight: 500 !important; 
}

.makepayment-thakyou-div {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
  min-height:600px;
}